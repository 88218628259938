/* Signature-like font styling */
.brand-name {
    font-size: 12px; /* Make it a bit larger for emphasis */
    // font-family: 'Dancing Script', cursive; /* Use the script font */
    font-weight: bold;
    color: #ffffff; /* Darker color for a classy signature look */
    transition: color 0.3s ease;
  
    &:hover {
      color: #007bff; /* Add subtle blue hover effect */
    }
  }
  
  /* Mobile Menu Adjustments */
  .menu-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px; /* Space for menu items */
  }
  
  .menu-item {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
  
    &:hover {
      color: #007bff;
    }
  }
  
  /* Responsive adjustments */
  .d-md-none {
    display: flex;
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none;
    }
  }
  
  .menu-item.active {
    color: #007bff; /* Example: Change text color */
    font-weight: bold;
  }
  